import { AbilityBuilder, Ability } from '@casl/ability'

export const AppAbility = Ability

const defineRulesFor = role => {
  const { can, rules } = new AbilityBuilder(AppAbility)

  switch (role) {
    case 'basic':
      can(['read'], ['/recipeGallery/', '/pricingPage/'])
      break

    case 'customer':
    case 'agent':
    case 'people':
      can(
        ['read', 'update'],
        [
          '/gastroairecipe/',
          '/myRecipes/',
          '/recipeGallery/',
          '/menuCreator/',
          '/pricingPage/',
          '/tasks/',
          '/usageGuide/'
        ]
      )
      break

    case 'team':
      can(
        ['read', 'update'],
        [
          '/mytasks/',
          '/tasks/',
          '/usageGuide/',
          '/gastroairecipe/',
          '/myRecipes/',
          '/recipeGallery/',
          '/menuCreator/',
          '/pricingPage/',
          '/home/'
        ]
      )
      break

    case 'admin':
    case 'developer':
      can('manage', 'all')
      break

    default:
      break
  }

  return rules
}

export const buildAbilityFor = (role, subject) => {
  return new AppAbility(defineRulesFor(role, subject), {
    detectSubjectType: object => object.type
  })
}

export const defaultACLObj = {
  action: 'manage',
  subject: 'all'
}

export default defineRulesFor
