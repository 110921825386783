// ** Icon Imports
import { Icon } from '@iconify/react'

const navigation = () => {
  return [
    {
      sectionTitle: 'Recipe Management',
      action: 'read',
      subject: '/gastroairecipe/'
    },
    {
      title: 'Recipe Generator',
      icon: 'mdi:chef-hat',
      path: '/gastroairecipe',
      action: 'read',
      subject: '/gastroairecipe/'
    },
    {
      sectionTitle: 'My Recipes',
      action: 'read',
      subject: '/myRecipes/'
    },
    {
      title: 'My Recipes',
      icon: 'mdi:food',
      path: '/myRecipes',
      action: 'read',
      subject: '/myRecipes/'
    },
    {
      title: 'Recipe Gallery',
      icon: 'mdi:book-open-page-variant',
      path: '/recipeGallery',
      action: 'read',
      subject: '/recipeGallery/'
    },
    {
      sectionTitle: 'Menu Creator',
      action: 'read',
      subject: '/menuCreator/'
    },
    {
      title: 'Menu Creator',
      icon: 'mdi:book-open-page-variant',
      path: '/menuCreator',
      action: 'read',
      subject: '/menuCreator/'
    },
    {
      sectionTitle: 'Tasks',
      action: 'read',
      subject: '/tasks/'
    },
    {
      title: 'Tasks',
      icon: 'mdi:calendar-check',
      path: '/tasks',
      action: 'read',
      subject: '/tasks/'
    },
    {
      title: 'My Tasks',
      icon: 'mdi:clipboard-list',
      path: '/mytasks',
      action: 'read',
      subject: '/mytasks/'
    },
    {
      sectionTitle: 'Pricing',
      action: 'read',
      subject: '/pricingPage/'
    },
    {
      title: 'Pricing',
      icon: 'mdi:currency-usd',
      path: '/pricingPage',
      action: 'read',
      subject: '/pricingPage/'
    },
    {
      sectionTitle: 'Help & Support',
      action: 'read',
      subject: '/usageGuide/'
    },
    {
      title: 'Usage Guide',
      icon: 'mdi:book-open-variant',
      path: '/usageGuide',
      action: 'read',
      subject: '/usageGuide/'
    }
  ]
}

export default navigation
