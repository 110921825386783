const DefaultPalette = (mode, skin) => {
  const white = '#FFFFFF'
  const black = '#000000'

  const colors = {
    primary: '#4A6FA5', // Soft Blue
    secondary: '#98B6E4', // Light Blue
    accent: '#FF6B6B', // Soft Red
    error: '#E63946', // Bright Red
    warning: '#F9A826', // Warm Yellow
    info: '#4A6FA5', // Soft Blue
    success: '#2A9D8F', // Teal
    grey: '#E0E0E0' // Light Grey
  }

  const lightShades = {
    background: '#FFFFFF',
    paper: '#F8F9FA',
    text: '#333333'
  }

  const darkShades = {
    background: '#2C3E50',
    paper: '#34495E',
    text: '#ECEFF1'
  }

  const shades = mode === 'light' ? lightShades : darkShades

  return {
    mode,
    common: { black, white },
    primary: {
      main: colors.primary,
      light: '#7895CB',
      dark: '#3A5683',
      contrastText: white
    },
    secondary: {
      main: colors.secondary,
      light: '#B1C9E8',
      dark: '#7895CB',
      contrastText: '#333333'
    },
    error: {
      main: colors.error,
      light: '#FF9999',
      dark: '#C62828',
      contrastText: white
    },
    warning: {
      main: colors.warning,
      light: '#FFCC80',
      dark: '#F57C00',
      contrastText: '#333333'
    },
    info: {
      main: colors.info,
      light: '#7895CB',
      dark: '#3A5683',
      contrastText: white
    },
    success: {
      main: colors.success,
      light: '#4ECDC4',
      dark: '#1A7A6E',
      contrastText: white
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161'
    },
    text: {
      primary: shades.text,
      secondary: mode === 'light' ? '#757575' : '#B0BEC5',
      disabled: mode === 'light' ? '#BDBDBD' : '#78909C'
    },
    divider: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
    background: {
      default: shades.background,
      paper: shades.paper
    },
    action: {
      active: mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
      hover: mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: mode === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.16)',
      disabled: mode === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
      disabledBackground: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
    },
    customColors: {
      buttonHover: mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.1)',
      cardOverlay: 'rgba(74, 111, 165, 0.8)',
      textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      tooltipBg: mode === 'light' ? '#333333' : '#ECEFF1'
    }
  }
}

export default DefaultPalette
