// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Firebase Imports
import {
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile
} from 'firebase/auth'

import { doc, setDoc, getDoc, collection, serverTimestamp, updateDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

// ** Firebase auth Import
import { auth, db } from 'src/configs/firebase'

// Initialize Firebase Functions
const functions = getFunctions()

// ** Next Import
import { useRouter } from 'next/router'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef)

        // If the user data exists in Firestore, set it in local state and local storage
        if (docSnap.exists()) {
          const userData = docSnap.data()
          setUser(userData)
          window.localStorage.setItem('userData', JSON.stringify(userData))
        }
      }
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const docRef = doc(db, 'users', result.user.uid)
      const docSnap = await getDoc(docRef)
      let userData
      if (docSnap.exists()) {
        // If the user already exists, get their data
        userData = docSnap.data()
      } else {
        // If the user doesn't exist, create a new user in Firestore
        // For direct sign-ups (not through team invite), set role as admin
        userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          role: 'admin' // Direct signup gets admin role
        }
        await setDoc(docRef, userData)
      }

      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData))

      const redirectURL = router.query.returnUrl && router.query.returnUrl !== '/' ? router.query.returnUrl : '/'
      router.replace(redirectURL)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const docRef = doc(db, 'users', result.user.uid)
      const docSnap = await getDoc(docRef)
      let userData
      if (docSnap.exists()) {
        userData = docSnap.data()
      } else {
        userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          role: 'admin' // Direct signup gets admin role
        }
        await setDoc(docRef, userData)
      }
      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData))

      const redirectURL = router.query.returnUrl && router.query.returnUrl !== '/' ? router.query.returnUrl : '/'
      router.push(redirectURL)
    } catch (error) {
      console.error('Facebook login failed:', error)
    }
  }

  const handleEmailLogin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      const docRef = doc(db, 'users', userCredential.user.uid)
      const docSnap = await getDoc(docRef)
      let userData
      if (docSnap.exists()) {
        // If the user data exists, get their data
        userData = docSnap.data()
      } else {
        // If the user doesn't exist, create a new user in Firestore
        userData = {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName || '',
          role: 'admin' // Direct signup gets admin role
        }
        await setDoc(docRef, userData)
      }

      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData))

      const redirectURL = router.query.returnUrl && router.query.returnUrl !== '/' ? router.query.returnUrl : '/'
      router.replace(redirectURL)
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = async () => {
    await signOut(auth)
    setUser(null)
    window.localStorage.removeItem('userData')
    router.push('/login')
  }

  const handleRegister = async (email, password) => {
    try {
      // Check if this is a team invite registration
      const isTeamInvite = router.query.teamId && router.query.memberId
      console.log('Registration type:', isTeamInvite ? 'Team Invite' : 'Direct Signup')

      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const docRef = doc(db, 'users', userCredential.user.uid)
      const docSnap = await getDoc(docRef)
      let userData

      if (docSnap.exists()) {
        console.log('User document already exists')
        userData = docSnap.data()
      } else {
        if (isTeamInvite) {
          console.log('Processing team invite registration')
          // For team invites, get the user data from the admin's team collection
          const teamMemberDoc = await getDoc(doc(db, `users/${router.query.teamId}/team`, router.query.memberId))

          if (teamMemberDoc.exists()) {
            console.log('Found team member data:', teamMemberDoc.data())
            const teamMemberData = teamMemberDoc.data()
            userData = {
              uid: userCredential.user.uid,
              email: userCredential.user.email,
              name: teamMemberData.name || '',
              role: 'team',
              position: teamMemberData.position || '',
              adminId: router.query.teamId,
              teamDocId: router.query.memberId
            }

            // Update the team member document with the verified status
            await updateDoc(doc(db, `users/${router.query.teamId}/team`, router.query.memberId), {
              status: 'active',
              updatedAt: serverTimestamp()
            })
          } else {
            console.error('Team member document not found')
            throw new Error('Invalid team invitation')
          }
        } else {
          console.log('Processing direct signup')
          // For direct signups, set role as admin
          userData = {
            uid: userCredential.user.uid,
            email: userCredential.user.email,
            displayName: userCredential.user.displayName || '',
            role: 'admin'
          }
        }

        console.log('Creating user document with data:', userData)
        await setDoc(docRef, userData)
      }

      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData))

      if (isTeamInvite) {
        router.push('/mytasks')
      } else {
        router.push('/')
      }
    } catch (error) {
      console.error('Registration error:', error)
      toast.error(error.message || 'Failed to register')
    }
  }

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const docRef = doc(db, 'users', result.user.uid)
      const docSnap = await getDoc(docRef)
      let userData
      if (docSnap.exists()) {
        userData = docSnap.data()
      } else {
        userData = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          role: 'admin' // Direct signup gets admin role
        }
        await setDoc(docRef, userData)
      }

      setUser(userData)
      window.localStorage.setItem('userData', JSON.stringify(userData))

      router.push('/')
    } catch (error) {
      console.log(error)
    }
  }

  const createTeamMember = async (email, userData) => {
    try {
      // Check if we're in the browser
      if (typeof window === 'undefined') return null

      // Get the current admin's user ID and team password
      const adminData = localStorage.getItem('userData')
      if (!adminData) {
        throw new Error('Admin data not found')
      }
      const { teamPassword } = JSON.parse(adminData)

      if (!teamPassword) {
        throw new Error('Team password not set. Please set a team password first.')
      }

      // Call the Firebase function to create team member
      const createTeamMemberFunction = httpsCallable(functions, 'createTeamMember')
      const result = await createTeamMemberFunction({
        email,
        userData,
        teamPassword
      })

      return result.data
    } catch (error) {
      console.error('Error creating team member:', error)
      throw error
    }
  }

  const setTeamPassword = async password => {
    try {
      // Check if we're in the browser
      if (typeof window === 'undefined') return null

      const userData = localStorage.getItem('userData')
      if (!userData) {
        throw new Error('Admin data not found')
      }
      const { uid } = JSON.parse(userData)

      // Update the admin's document with the team password
      const adminDocRef = doc(db, 'users', uid)
      await updateDoc(adminDocRef, {
        teamPassword: password,
        updatedAt: serverTimestamp()
      })

      // Update local storage
      const updatedUserData = JSON.parse(userData)
      updatedUserData.teamPassword = password
      window.localStorage.setItem('userData', JSON.stringify(updatedUserData))

      return { success: true }
    } catch (error) {
      console.error('Error setting team password:', error)
      throw error
    }
  }

  const generateChatbotToken = async chatbotId => {
    // Check if we're in the browser
    if (typeof window === 'undefined') return null

    const chatbotToken = Math.random().toString(36).slice(-12) + Math.random().toString(36).slice(-12)
    const chatbotRef = doc(db, 'chatbots', chatbotId)
    await setDoc(chatbotRef, { token: chatbotToken }, { merge: true })
    return chatbotToken
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    register: handleRegister,
    googleSignup: handleGoogleSignup,
    emailLogin: handleEmailLogin,
    logout: handleLogout,
    facebookLogin: handleFacebookLogin,
    createTeamMember,
    setTeamPassword,
    generateChatbotToken
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
